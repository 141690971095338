.default-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  font-size: 1rem;
  color: white;
  background: #392F2A;
  border-color: #392F2A;


  &:focus, &:active, &:hover {
    outline: none !important;
    box-shadow: none !important;
    border-color: #eb002d !important;
    background-color: #eb002d !important;
  }
  &:disabled {
    border-color: #eb002d !important;
    background-color: #eb002d !important;
    cursor: not-allowed;
  }

  svg {
    margin: 0 0.5rem;
    font-size: 1.7rem;
  }
}
