.reset-password-email-page, .reset-password-sent-page, .on-boarding-social-media-page {
    .page-main-block {
      .page-main__inner {
        display: flex;
        justify-content: center;
        padding-top: 0 0.5rem;
      }
    }
  }
   
      .page-main__login {
        display: flex;
        justify-content: center;
        padding-top: 0 0.5rem;
        backgroundColor:'red';
      }
    
  
  .reset-password__desp {
      display: flex;
      justify-content: center;
      padding-top: 0.5rem;
  }
  .reset-password__content{
    display: flex;
    justify-content: center;
    padding-top: 3rem;
  }
  
  .social-media-input-control {
    margin-bottom: 1rem;
    .input__inner {
      display: flex;
      flex-direction: row;
      align-items: center;
  
      img {
        background-color: white;
        margin-right: 0.7rem;
      }
      .media--input {
        width: 100%;
        .input-form-row {
          .form-group {
            margin: 0;
            .form-input-box {
              border-radius: 1rem;
              border: 1px solid #a8a6a6;
              font-size: 0.75rem;
            }
          }
        }
      }
    }
  }

  .page-footer {
    .footer-content {
      font-size: 0.8rem;
      color: whitesmoke !important;
      a {
        color: whitesmoke !important;
        text-decoration: underline;
      }
    }
  }
  