.App {
 /* background-color: #373535;*/
    /* background-color: #fff;
    margin: auto;
    width: 38%;
    color: #000;
    margin-top: 2rem;
    min-height: 540px;*/

}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.container{
  background-color: #e6e6e6;
    padding: 30px;
    border-radius: 10px;
}
.newlogin-cls{
   background-color: #fff;
    margin: auto;
    width: 38%;
    color: #000;
    margin-top: 0;
    min-height: 540px;
}

._1HYmY:focus {
    outline: none;
    /* box-shadow: none; */
    color: whitesmoke;
    background: none;
    /* border: none; */
    border-radius: 0;
    border: 1px solid #a8a6a6 !important;
    border-left: 2px solid #33aece !important;
}
.btn-primary{
    background-color: #fecf38 !important;
    border-radius: 30px;
    border: none;
    color: #000;
    width: 75%;
    margin-top: 2rem;
    width: 74%;
    display: block;
    margin-right: 2rem;
    margin-left: 4.5rem;
    padding: 12px;
}
._1HYmY {
  color: #000 !important;
  border: 1px solid #e0dddd;
  border-left: 2px solid #33aece;
  height: 3rem;
  display: block;
  margin-right: 2rem;
  margin-left: 5rem;
  width: 72%;
  margin-bottom: 0px !important;
  padding: 6px;
}

._3nWpX{
  color: red !important;
  width: 72%;
  display: block;
  margin-right: 2rem;
  margin-left: 5rem;
}
._2vOQS svg{
  right: 5vw;
}
form {
  margin-top: 2rem;
}
.px-0 {
  color: #000;
  width: 72%;
  display: block;
  margin-right: 2rem;
  margin-left: 5rem;
  margin-top: 1rem;
}

.headingcls{
  font-weight: normal;
  font-size: 34px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-black{
  background-color: #34373d;
  
  background-size: contain;

  color: #ffffff;
}
.inputbg-black{
  background-color: #ffffff;
  justify-content: center;
  background-size: cover;
  

  
}
.input-black{
  border: 1px solid #000;
  margin:  4px;
  color: #e0e0e0;
  width: 5%;
  
}
.vh-100{
  height: 100vh;
}
.w100p{
  width: 100%;
}
.align-origin{
  display: grid;
  place-items: center;
  height: 10vh;
  font-weight: bold;
  color: #fff;
  font-size: 30px;
}
.align-originspam{
  display: grid;
  place-items: center;
  
  font-weight: bold;
  color: #fff;
  font-size: 15px;
}

.err{
  border: 1px solid red;
}
.red{
  color: red !important;
}
.logo-div{
  background-image: url('assets/img/wallpon.png');
  background-repeat:no-repeat;
  height: 70px;
  background-position: center;
  display: block;
  top: 3rem;
  margin-bottom: 5rem;
  position: relative; 
}

.end-div{
  margin-bottom: 5rem;
}



