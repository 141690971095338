.input-form-row {
  margin: 0 !important;

}

.input-form-group {
  width: 10%;
  text-align: start;
  margin-bottom: 0rem;
}

.text-label {
  margin: 0;
  font-size: 0.7rem;
  //opacity: 0.6;
  color: gray;
  font-weight: 500;
}

.form-input-box {
  color: whitesmoke;
  /*background: #2A3439;*/
  border: 1px solid #e0dddd;
    border-left: 2px solid #33aece;
  border-radius: 0;
  
  font-size: 0.9rem;
  font-weight: 600;
  height: 42px;


  &:focus {
    outline: none;
    box-shadow: none;
    color: whitesmoke;
    background: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #a8a6a6;
  }
}

.select-input-box {
  
  min-width: 10rem;
  font-size: 0.9rem;
  font-weight: 600;
  height: 2rem;

  .css-1uccc91-singleValue {
    color: #ffffff;
    opacity: 50%;
  }

  &:focus {
    outline: none !important;
  }

  div[class*="-control"]{
    background: transparent !important;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #a8a6a6;
  }

  div[class*="-ValueContainer"] {
    div[class$="-placeholder"] {
      &:focus {
        outline: none !important;
      }
    }
    div[class$="-singleValue"] {
     color: whitesmoke;
    }
  }
  span[class*="-indicatorSeparator"]{
    display: none !important;
  }
  svg {
    opacity: 0.5;
  }
  .css-1pahdxg-control {
    box-shadow: none !important;
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
  }
}

.error-select-box {
  div[class*="-control"]{
    border-color: #dc3545;
  }
}

.error-mask-input{
  border-color: #dc3545;
}

.select-error {
  display: block;
  margin-top: 0.5rem;
}
