.login {
 /* background-color: #373535;*/
    background-color: #fff;
    margin: auto;
    width: 38%;
    color: #000;
    margin-top: 32rem;
    min-height: 540px;

}
.bg-black{
  background-color: #fff;
  /*background-image: url('assets/img/Path -1.png');*/
  background-size: contain;

  color: #ffffff;
}

.signup-link{
	text-align: center;
}
.signup-link-a{
	font-size: 16px;
	color: #fff;
}
.label-class{
  font-size: 16px;
   margin-left: 5px;
   position:relative;
   bottom:6px;
}
.remember-class{
  font-size: 16px;
    margin-left: 7px;
    margin-top: 6px;
    position: absolute;
}
.btn-adminlogin{
  background-color: #fecf38 !important;
    border-radius: 30px;
    border: none;
    color: #fff;
    margin-top: 2rem;
    display: block;
    padding: 12px;
}
.default-button:focus, .default-button:active, .default-button:hover {
    outline: none !important;
    box-shadow: none !important;
    border-color: #fecf38 !important;
    background-color: #fecf38 !important;
}