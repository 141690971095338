.mainpage{
  background-color: #ffffff;
  padding: 0px;
  height: auto !important
  
}
.background{
 background-color: #34373d;

 height: auto !important;
  min-height:800px;
  width:190px;
  max-width:190px;
}
.input{
  width:80px;
}
.inputarea{
  width:100px;
}
.inputborder{
  border:none;
  background-color: transparent;
  width:70px;
}
.anav-link{
  color:#ffffff
}
.anav-link:hover{
  color:#6DBE45
}
.modaltext{
  color:#000000
}

.masterlabel{text-align: center;color: inherit;}
.masterlabel:hover {
    color: inherit;
    text-decoration: none;
}
.error{ background:rgba(0,0,0,.075); border:2px solid #b3b3b3}
.anav-linksel{
  color:#000000
}
.col-md-2{
  flex:none;
}
.anav-linksel:hover{
  color:#000000
}
.scraptable  th{
  border-bottom: 0 !important;
  padding:.55rem;
}
.scrpdataval  td{
    border: 1px solid #DDEFEF !important;
    font-size: 13px;
    padding: 10px 10px 0;
}
.react-switch-bg{
  height: 27px !important;
}
.react-switch-bg svg{ display:none;}
.react-switch-handle{
  height: 20px !important;
  width:20px !important;
  top:3px !important;
}
.scrpdataval label{
      margin-bottom: 0;
}
.sidebar{
  z-index:1;
  width:100%;
  height: auto; 
}
.text{
  color:black;
  background-color:#fff
}

.navbar{
  padding: .5rem 0rem;
  display:block;
}

.span-right{
  color: #000;
  float: right;
  padding-right: 16px;
  margin-top: 10px;
}

.icon-div{
 
  background-repeat:no-repeat;
  height: 30px;
  position: relative;
}

.icon-div2{
  background-repeat:no-repeat;
  height: 35px;
  position: relative;
}

.icon-div3{
 
  background-repeat:no-repeat;
  height: 30px;
  position: relative;
}

.icon-divmain{
  position: relative;
  top: -20px;
  left: 1rem;
}

.img-val{
  position: relative;
  top: -30px;
  float: left;
  
  font-weight: 600;
}
.navBg {
  position: absolute;
  max-width: 196px;

  
  

  nav {
     position: top;
     
     height: auto;

   /* padding-top: -75px;*/
    h1 {
      color: #34495e;
      font-size: 24px;
      font-weight: 300;
      padding: 25px 12px;
      background-color: white;
    }
    a {
      transition: 0.6s;
      padding-bottom: 15px;
      padding-top: 15px;
      color: white;
      border-bottom: 1px solid white;
      font-weight: 300;
      &:hover {
        background-color: white;
        color: #34495e;
        i {
          color: #34495e;
        }
      }
    }
    i {

      padding-right: 10px;
    }
    small {
      color: white;
      text-align: center;
      margin-top: 15px;
    }
  }
}
@media (max-width: 767px) {
  .navBg {
    height: 100%;
    nav {
      padding-top: 0px;
    }
  }
}
@media (max-width: 576px) {
   .background{
     width: 100%;
     max-width: 100%;
     min-height: 340px;
   }
   .mobiletop{
    top:10rem !important;
   }
   .newlogin-cls{
       width: 96%;
   }
   ._1HYmY{
    margin-left: 2rem;
    width: 90%;
   }
  .navBg {
    height: 100vh;
    max-width: 55px;
    nav {
      padding-top: 0px;
      h1 {
        display: none;
      }
      a {
        text-align: center;
      }
      i {
        padding-right: 0px;
      }
      span {
        display: none;
      }
    }
  }
}
@media screen and (max-height: 600px) {
  .navBg {
    height: 100%;
    nav {
      padding-top: 0px;
    }
  }
}
.activebk{background: #ddffff;}

.tableFixHead {
        overflow-y: auto;
        height:44vw;
      }
     
.sidebar .nav-link{
    width: 190px;
    margin-left: -15px;    
    padding: .5rem 3rem .5rem 2rem;
}    

.zui-table {
    font: normal 13px Arial, sans-serif;
    width: 81vw;
    max-width: 81vw;
    border-collapse: collapse;
}
.zui-table thead th {
    background-color: #DDEFEF;
    border: none;
    color: #336B6B;
    padding: 10px 2px;
    text-align: center;
    text-shadow: 1px 1px 1px #fff;
    width:70px;
    position: sticky;
    top: 0;
    z-index:1;
    cursor:pointer;
}
.zui-table tbody td {
    border-bottom: solid 1px #DDEFEF;
    color: #333;
    padding: 10px 2px;
    text-shadow: 1px 1px 1px #fff;
    width:70px;
    text-align:center;
}
.zui-wrapper {
    position: relative;
}
.zui-scroller {
    margin-right: 141px;
    overflow-x: scroll;
    overflow-y: visible;
    padding-bottom: 5px;
    width: 300px;
}
.zui-table .zui-sticky-col {
    border-bottom: none;
    right: 0;
    position: fixed;
    top: auto;
    width: 120px;
    background:#DDEFEF;
    height:40px;
}
.btn-clear {
    background-color: #ffffff;
    border-color: #28a745;
}
.btn-clearActive {
    background-color: #28a745;
    border-color: #28a745;
    color: #ffffff;
    box-shadow: none !important;
}
.react-datepicker-popper {
    z-index: 1005 !important;
}


.pagination a {
  color: #000000 !important;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
}

.pagination a.active {
  background-color: #4CAF50;
  color: #ffffff !important;
  border: 1px solid #4CAF50;
}
.form-input-box{
  color:#333333 !important;
}
.pagination a:hover:not(.active) {background-color: #ddd;}
.table th a{
  cursor:pointer;
  color: #000000;
}
.form-control1 {
    display: block;
    width: 100%;
    height: 30px;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-bottom: 9px;
}
.btn-success, .btn-danger{ padding:2px 15px; hight:20px; font-size:14px; margin-top:3px;margin-left:5px;}

/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 2% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 60%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.getwidthcss{ width:20%;    margin-left: 0;}
.getredbtn{  background-color:#ff0000 !important;color: #fff}
.getgraybtn{  background-color:#c3c3c3 !important;color: #fff}
.getblackbtn{background-color:#000000 !important;color: #fff}
.getblackbtn:hover{ color: #ff0000}
.getredbtn:hover{ color:#000000}
.getgraybtn:hover{ color:#000000 }


