.account-verification-page {
  .verification-main-block {
    .v__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .rqt-content {
        font-size: 0.86rem;
        text-align: center;
      }
      .otp-submit-btn {
        width: 100%;
        background-color: #eb002d;
        font-size: 0.86rem;
        padding: 0.7rem 0;

        @media only screen and (min-width: 768px) {
          width: 70%;
        }
      }
      .otp-expire-content {
        font-size: 0.8rem;
      }
      .resend-otp-btn {
        background: transparent;
        border: none;
        font-size: 0.9rem;
        &:focus, &:active, &:hover {
          outline: none !important;
          box-shadow: none !important;
          background: transparent !important;
          border: none !important;
        }
      }
    }

    .rqt-block {
      padding: 0 3.5rem;
    }
    @media only screen and (max-width: 320px) {
      .rqt-block {
        padding: 0 2rem;
      }
    }

    .otp-input-block {
      margin: 0.5rem 0 2rem;
      .otp-container {
        display: flex;
        justify-content: center;

        .otp-input {
          width: 3rem !important;
          height: 3rem;
          margin: 0 0.5rem;
          font-size: 2rem;
          color: whitesmoke;
          background-color: transparent;
          border: none;
          border-bottom: 1px solid #a8a6a6;

          &:focus, &:active {
            outline: none !important;
            color: whitesmoke;
            background-color: transparent;
          }
        }
        .otp-input-focus {
          outline: none !important;
        }
      }
    }
  }

  .page-footer {
    .footer-content {
      font-size: 0.8rem;
      color: whitesmoke !important;
      a {
        color: whitesmoke !important;
        text-decoration: underline;
      }
    }
  }
}
