.page-area {
  min-height: 100vh;
  position: relative;
}

.page-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
